/* STORE LOCATOR */
function creerLigneListe(
    cpt,
    id,
    nom,
    adresse,
    ville,
    cp,
    pays,
    currently_open,
    horaire_today,
    distance,
    marqueur,
    picture_filtre,
    magasinId,
    stock,
    stockStatus,
    stock_quantity,
    clientId,
    favorite,
    beContacted,
    flag_available,
    url,
    shop_filter,
    telephone,
    query,
    lat,
    lng
) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement("li");
    li.id = id;
    li.dataset.lng = lng;
    li.dataset.lat = lat;

    li.className = "elem-list-store";

    if (favorite == "1") {
        choose_fav = Translator.translate("actual_favorite_store");
    } else {
        choose_fav = Translator.translate("choose_as_favorite_store");
    }

    /***** Product stock infos *****/
    if (stock_quantity == "undefined" || stock_quantity == "") {
        stock_status_msg = "unknow_stock";
    } else {
        var in_stock = stock_quantity >= 6 && stock_quantity <= 9999;
        var limited_stock = stock_quantity >= 1 && stock_quantity <= 5;
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = "";
        if (in_stock) {
            stock_status_msg = "in_stock";
        }
        if (limited_stock) {
            stock_status_msg = "limited_stock";
        }
        if (not_in_stock) {
            stock_status_msg = "not_in_stock";
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = "to_be_confirmed_stock";
        }
        if (can_be_ordered_stock) {
            stock_status_msg = "can_be_ordered_stock";
        }
    }

    var info_horaire_today = !horaire_today
        ? Translator.translate("store_closed")
        : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_closed";

    if (lat != "" && lng != "" && !$("body.product_page").length) {
        var onclickGeo = `onclick="geolocGoto(${lat}, ${lng})";`;
    } else {
        var onclickGeo = "";
    }

    var ligneListe =
        `<div class="elem_list_contents" ${onclickGeo}> <div class="nom_store">`;

    if (distance) {
        ligneListe +=
            '<h2 class="title">' +
            nom +
            '<p class="distance">' +
            "(" +
            distance +
            "km)" +
            "</p></h2>";
    } else {
        ligneListe += '<h2 class="title">' + nom + "</h2>";
    }
    ligneListe += "</div>" + '<div class="content_store">';

    if ($("body.product_page").length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url
        }'">`;
    } else {
        ligneListe += '<div class="detail_store">';
    }
    ligneListe +=
        '<div class="col-1">' +
        '<span class="rue_store">' +
        adresse +
        "</span>" +
        '<span class="ville_store">' +
        " - " +
        cp +
        " " +
        ville +
        " " +
        '<span class="pays_store">' +
        pays +
        "</span>" +
        "</span>" +
        '<span class="tel_store">' +
        telephone +
        "</span></div>";

    var is_page_product = $("body.product_page").length == 1;

    if (
        (info_horaire_today !== "" && currently_open) ||
        (!currently_open && horaire_today)
    ) {
        ligneListe +=
            '<div class="col-2"><p class="horaire_today puce_' +
            class_horaire_today +
            '">' +
            info_horaire_today +
            "</p></div>";
    }

    ligneListe += "</div>";
    if (document.getElementById("produit_id")) {
        /***** Product stock infos msg *****/
        if (stock != "undefined") {
            ligneListe +=
                '<p class="stock_status ' +
                stock_status_msg +
                '">' +
                Translator.translate(stock_status_msg + "_msg") +
                "</p>";
        }
    }

    if (is_page_product) {
        if (beContacted == 1) {
            if ($("body.product_page.mobile").length) {
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChangeMobile(' +
                    id +
                    ')">' +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChange(\'' +
                    id +
                    "','" +
                    nom_mag +
                    "')\">" +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            }
        } else {
            ligneListe += "</div>" + "</div>";
        }
    }

    isFavorite = favorite ? "favorite_store_js" : "";
    ligneListe +=
        '<a href="javascript:;" class="addfavorite" id="store' +
        magasinId +
        '" onclick="addToMyFavorite(\'' +
        magasinId +
        "', '" +
        clientId +
        "', '" +
        favorite +
        "', '" +
        escape(info_horaire_today) +
        "','" +
        currently_open +
        "','" +
        escape(horaire_today) +
        "','" +
        class_horaire_today +
        "','" +
        stock +
        "','" +
        stockStatus +
        "'); return false;\">";
    ligneListe +=
        '<span class="choose_fav_label" id="favShop-' +
        magasinId +
        '"><span class="' +
        isFavorite +
        '">' +
        choose_fav +
        "</span></span></a></div></div>";

    if (!is_page_product) {
        ligneListe +=
            '<div class="store_more"><a onclick="createDataLayer(' +
            id +
            ')" href="' +
            path_relative_root +
            url +
            '">' +
            Translator.translate("afficher_horaires") +
            "</a>" +
            "</div>";
    }

    ligneListe += "</div>";

    li.innerHTML = ligneListe;

    li.addEventListener("click", function () {
        google.maps.event.trigger(marqueur, "click");
    });

    return li;
}

function creerMarqueur(
    point,
    nom,
    adresse,
    ville,
    cp,
    pays,
    horaire,
    telephone,
    email,
    site,
    image_mag,
    type_mag,
    cpt,
    magasin_id,
    url,
    distance,
    horaire_today,
    currently_open
) {
    var img = path_relative_root + "img/maps/marker.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(
        img,
        // This marker is 20 pixels wide by 32 pixels tall.
        new google.maps.Size(38, 45),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0)
    );

    // The anchor for this image is the base of the flagpole at 0,32.
    //new google.maps.Point(10, 26));
    var shadow = new google.maps.MarkerImage(
        path_relative_root + "img/indic_carte_ombre.png",
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0, 0),
        new google.maps.Point(10, 0)
    );
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.
    var marqueur = new google.maps.Marker({
        position: point,
        icon: image,
    });

    marqueur.set("magasin_id", magasin_id);
    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? "" : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_close";
    var currently_open_info = currently_open
        ? Translator.translate("store_opened")
        : Translator.translate("store_closed");

    var cpt_recherche = typeof cpt != "undefined" ? "(" + cpt + ") - " : "";

    if (distance) {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            '</a><span class="dist">(' +
            distance +
            " km)</span></div>";
    } else {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            "</a></div>";
    }

    ligneListe +=
        '<div class="adresse">' +
        adresse +
        " - " +
        cp +
        " " +
        ville +
        "<br />" +
        pays +
        "</div>";

    if (telephone != "")
        ligneListe += '<div class="coord">' + telephone + "</div>";

    if (site != "")
        if (!http.test(site)) {
            ligneListe +=
                '<a href="http://' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        } else {
            ligneListe +=
                '<a href="' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        }

    if (horaire != "")
        if (class_horaire_today == "store_open") {
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                "</span>" +
                info_horaire_today +
                '</div><div class="horaire_info">' +
                horaire +
                "</div>";
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                '</span></div><div class="horaire_info">' +
                horaire +
                "</div>";
        }
    // Tooltip more infos & go to store link
    ligneListe += `<div class="store_localisation_container">
        <div class="btn_voir_magasin go_to_store"><a class="geolocalisation_link" target="_blank" href="https://maps.google.fr/maps?daddr=${point.lat()},${point.lng()}">${Translator.translate(
        "go_to_store"
    )}</a></div>
        <div class="btn_voir_magasin"><a href="${url}">${Translator.translate(
        "voir_magasin"
    )}</a></div>
        </div>`;
    // Add marker image inside tooltip
    ligneListe += `<div class="store_marker"><img src="${image.url}"/></div>`;

    google.maps.event.addListener(marqueur, "click", function () {
        // selected store coordinates
        var storePosition = new google.maps.LatLng(point.lat(), point.lng());
        // center map on marker
        maCarte.setCenter(storePosition);
        // map animation on marker switch
        maCarte.panTo(storePosition);

        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id;

    markersArray.push(marqueur);

    return marqueur;
}

function addToMyFavorite(
    magasin_id,
    client_id,
    favorite,
    info_horaire_today,
    currently_open,
    horaire_today,
    class_horaire_today,
    stock,
    stockStatus
) {
    event.preventDefault();
    if (client_id == 0) {
        var connect_link = path_relative_root + create_link("connexion_login");
        alert(
            '<div class="box_txt_wishlist selected_mag"><h2>' +
            Translator.translate("add_store_login_error_title") +
            "</h2><br>" +
            Translator.translate("add_store_login_error_text") +
            '<br><button class="button primary" onclick="favStoreRedirectLogin()">' +
            Translator.translate("vgt_connexion") +
            " </button></div>"
        );
    } else {
        if ($(".favorite_store_js").length == 0) {
            var act = "add";
        } else {
            var act = "del";
        }

        $.ajax({
            url: path_relative_root + "ajax_manage_favorite_store.php",
            type: "post",
            dataType: "html",
            data:
                "act=" +
                act +
                "&magasin_id=" +
                magasin_id +
                "&client_id=" +
                client_id,
            success: function (response) {
                response = JSON.parse(response);
                var getCustomerFavoriteStore = document.querySelector(
                    "#customerFavoriteStore"
                );
                var customerFavoriteStore = false;
                if (getCustomerFavoriteStore) {
                    customerFavoriteStore = customerFavoriteStore.value;
                }
                var shopName = document.querySelector(".mag_name_fav");
                var shopStock = document.querySelector(".mag_stock_fav");
                var shopHours = document.querySelector(".mag_hours");
                var shopTown = document.querySelectorAll(".mag_ville");
                var shopStreet = document.querySelector(".mag_rue");
                var shopCountry = document.querySelector(".mag_pays");
                var shopCp = document.querySelector(".mag_cp");

                var noStoreSelected =
                    document.querySelector("#no_store_selected");
                var noStoreSelectedHidden = document.querySelector(
                    "#no_store_selected_hidden"
                );

                var shopList = document.querySelector(".liste_distributeur");

                var customerHeartFav = document.querySelector(
                    "#heartFav-" + magasin_id
                );
                var allHeartFav = document.querySelectorAll(".heartFav");

                var currentFavStore =
                    document.querySelector("#currentFavStore");
                var hiddenProductFavStore = document.querySelector(
                    "#hiddenProductFavStore"
                );
                var noFavShop = document.querySelector("#noFavShop");

                if (allHeartFav) {
                    for (var i = 0; i < allHeartFav.length; i++) {
                        allHeartFav[i].className = "heartFav";
                    }
                }
                if (hiddenProductFavStore && noFavShop) {
                    noFavShop.style.display = "none";
                    hiddenProductFavStore.style.display = "flex";
                }

                if (customerHeartFav) {
                    customerHeartFav.className = "heartFav";
                    customerHeartFav.className = "selected";
                }

                if (shopList) {
                    shopList.className += " withfav";
                }
                if (
                    noStoreSelected &&
                    noStoreSelectedHidden &&
                    !currentFavStore
                ) {
                    noStoreSelected.style.display = "none";
                    noStoreSelectedHidden.style.display = "block";
                }

                if (shopName) {
                    shopName.innerHTML = response.store.magasin_nom;
                }

                if (shopTown) {
                    for (var i = 0; i < shopTown.length; i++) {
                        var town = shopTown[i];
                        town.innerHTML = response.store.magasin_ville;
                    }
                }
                if (shopStreet) {
                    shopStreet.innerHTML = response.store.magasin_adresse;
                }
                if (shopCp) {
                    shopCp.innerHTML = response.store.magasin_cp;
                }
                if (shopCountry) {
                    shopCountry.innerHTML = response.store.magasin_pays;
                }
                if (stock != "null") {
                    shopStock.innerHTML = Translator.translate(
                        stock.slice(0, -3)
                    );
                    shopStock.setAttribute("data-status", stockStatus);
                }

                if (shopHours) {
                    if (
                        (info_horaire_today !== "" && currently_open) ||
                        (!currently_open && horaire_today)
                    ) {
                        shopHours.innerHTML =
                            '<span class="horaire_today"><span class="puce ' +
                            unescape(class_horaire_today) +
                            '"></span> ' +
                            unescape(info_horaire_today) +

                            "</span>";
                    } else {
                        shopHours.innerHTML = "";
                    }
                }

                var storeClient = document.getElementById(
                    "favShop-" + magasin_id
                );
                var allStore =
                    document.getElementsByClassName("choose_fav_label");
                var countAllStore = allStore.length;

                for (var i = 0; i < countAllStore; i++) {
                    allStore[i].innerHTML =
                        "<span>" +
                        Translator.translate("choose_as_favorite_store") +
                        "</span>";
                }

                if (response.status == "add") {
                    storeClient.innerHTML =
                        '<span class="favorite_store_js">' +
                        Translator.translate("actual_favorite_store") +
                        "</span>";
                } else if (response.status == "delete") {
                    storeClient.innerHTML =
                        "<span>" +
                        Translator.translate("choose_as_favorite_store") +
                        "</span>";
                }
            },
        });
    }
    return false;
}

// popup PDV
function showpdv() {
    var shade, modbox;
    $.ajax({
        type: "post",
        url: path_relative_root + create_link("ajax_search_shop"),
        success: function (res) {
            if (res) {
                shade = document.querySelector("#shad.modal_shade");
                modbox = document.getElementById("pdvBox");

                // Make sure modbox will show on top of shad
                $(shade).after(modbox);

                // Inject store locator into modbox
                $(".store_locator").html(res);

                // magasin_gmap.js
                initialize();

                // Show modbox and make sure closing routine won't fire more than once

                $(shade).off("click", closepdv);
                $(modbox).addClass("deployed");
                $(shade).addClass("actif");
                $(shade).on("click", closepdv);
                $("#pdv_load").removeClass("loading");
            }
        },
    });
}

/* ------------------------------ FAVORITE STORE ------------------------------ */
function addToMyFavorites() {
    var is_favorite_elem = document.getElementById("is_favorite");

    var self = $(this);
    var magasin_id = self.attr("data-magasinid");
    var client_id = self.attr("data-clientid");
    var action = self.attr("data-action");

    // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
    if (action == "destroy") {
        var act = "del";
    } else {
        // On est dans store_details et on doit recupérer le statut actuel du magasin pour savoir si on doit le supprimer ou l'ajouter
        if (is_favorite_elem.value == "1") {
            var act = "del";
        } else {
            var act = "add";
        }
    }

    $.ajax({
        url: path_relative_root + "ajax_manage_favorite_store_detail.php",
        type: "post",
        dataType: "html",
        data: "act=" + act + "&magasin_id=" + magasin_id,
        success: function (response) {
            if (response) {
                // Destruction du bloc
                if (action == "destroy") {
                    self.slideUp("slow", function () {
                        self.remove();
                    });
                } else {
                    if (act == "add") {
                        var libelle =
                            "<span>" +
                            Translator.translate("delete_to_favorites") +
                            "</span>";

                        is_favorite_elem.value = 1;
                    } else {
                        var libelle =
                            "<span>" +
                            Translator.translate("add_to_favorites") +
                            "</span>";

                        is_favorite_elem.value = 0;
                    }
                    self.html(libelle);
                }
            }
        },
    });
}

function initCoverModuleSwiper(){
    var coverSwiper = new Swiper('#cover_module_swiper', {
        slidesPerView: 1,
        loop: true,
        speed: 2000,
        autoplay: 1000,
        autoplayDisableOnInteraction: false,
        pagination: '#cover_module_swiper .swiper-pagination',
        paginationClickable: true,
        paginationtype: "bullets",
    });

    // Simulate an interaction to trigger the data-swiper-autoplay reset
    coverSwiper.stopAutoplay(); // Stop the autoplay
    setTimeout(() => {
        coverSwiper.startAutoplay(); // Restart the autoplay
    }, 100); // Adjust the timeout as needed
};

$(function () {
    if ($('body.store_locator').length) {
        var geolocate_btn = '<span>' + Translator.translate('geolocate_btn') + '</span>';

        $("body.store_locator #search_cp + #geoloc_btn").append(geolocate_btn);
    }

    // Store Loc popup boutique info map
    function checkAndWrapElements() {
        if ($('.store_locator .bloc_marker_info').length) {
            // Check if elements are not already wrapped
            $('.store_locator .bloc_marker_info').each(function () {
                if (!$(this).find('.boutique_info').length) {
                    $(this).find('.marker_store_adr').append(" - ");
                    // Wrap elements if they exist and are not already wrapped
                    $(this).find('.marker_store_adr, .marker_store_cp').wrapAll('<div class="boutique_info"></div>');
                }
            });
            $('.store_locator .gm-style-iw.gm-style-iw-c').css('opacity', '1')
        }
    }

    setInterval(checkAndWrapElements, 1000);

    setTimeout(function () {
        $('.banner_nav .cache').removeClass('cache');

        $('#left_menu .main_menu_itm.menu_itm_hover:has(.submenu_wrapper)').each(function () {
            var hasCategs = true;

            if ($(this).find('.wrap_links').length && ($(this).find('.wrap_links')[0].innerHTML.trim() == '' || $(this).find('.wrap_links')[0].innerHTML.trim() == undefined)) {
                hasCategs = false;
            }

            if (hasCategs) {
                $(this).click(function () {
                    $(this).toggleClass('actif');
                    $(this).find('.submenu_wrapper').slideToggle();
                    event.preventDefault();
                });

                $(this).find('.submenu_wrapper a').click(function (event) {
                    event.stopPropagation();
                });
            }
        });

        $('.switchLangWrapper .bloc_lnk').click(function () {
            $('#left_menu .main_menu_itm').removeClass('actif');
            $('#left_menu .submenu_wrapper').slideUp();
            $(this).toggleClass('actif').next('.langues').find('#switch_lang').slideToggle();

            var bottomMenuPart = $(this).parents('.menu_bottom_wrapper');

            if (bottomMenuPart.length > 0) {
                var elementOffset = $(this).offset().top - bottomMenuPart.offset().top + bottomMenuPart.scrollTop();

                bottomMenuPart.animate({
                    scrollTop: elementOffset
                }, 500);
            }
        });

        $('.search_trigger').on('click', function () {
            $('body').removeClass('search_hidden');
            $('#top_searchV2 #form_recherchev2').toggleClass('open');
            $('body').addClass('fixed');

            if ($("#top_searchV2 #form_recherchev2").hasClass("open")) {
                $('#shade').addClass('visible').on("click", function () {
                    $('#shade').removeClass('visible');
                    $('.search_trigger').removeClass('actif');
                    $('#top_searchV2 #form_recherchev2').removeClass('open');
                    $('body').removeClass('fixed');
                });
                $('.search_trigger').addClass('actif');
            } else {
                $('#shade').removeClass('visible');
                $('.search_trigger').removeClass('actif');
                $('body').removeClass('fixed');
            }

            if ($('#main_menu_btn').hasClass('menu_open')) {
                $('#main_menu_btn').removeClass('menu_open');
                $('#left_menu').removeClass('open');
            }

            if ($('#user_btn').hasClass('active')) {
                $('#user_btn').removeClass('active');
                $('#account_menu').removeClass('active');
                $('#main_menu_btn').removeClass('hide');
            }
        });

        $('#main_menu_btn .menu').on('click', function () {
            if ($('#user_btn').hasClass('active')) {
                $('#user_btn').removeClass('active');
                $('#account_menu').removeClass('active');
                $('#main_menu_btn').removeClass('hide');
            }

            if ($("#top_searchV2 #form_recherchev2").hasClass("open")) {
                $('.search_trigger').removeClass('actif');
                $('#top_searchV2 #form_recherchev2').removeClass('open');
                $('body').removeClass('fixed');
            }
        });

        $('#user_btn .user').on('click', function () {
            if ($('#main_menu_btn').hasClass('menu_open')) {
                $('#main_menu_btn').removeClass('menu_open');
                $('#left_menu').removeClass('open');
            }

            if ($("#top_searchV2 #form_recherchev2").hasClass("open")) {
                $('.search_trigger').removeClass('actif');
                $('#top_searchV2 #form_recherchev2').removeClass('open');
                $('body').removeClass('fixed');
            }

            if ($('#user_btn').hasClass('active')) {
                $('#main_menu_btn').removeClass('hide');
            } else {
                $('#main_menu_btn').addClass('hide');
            }
        });

        // Lien reassurance FAQ dans le footer
        if (~window.location.href.indexOf("#paiement")) {
            window.setTimeout(function () {
                $('.faq_theme_3 .faq_title').click();
            }, 200);
        }

        if (~window.location.href.indexOf("#payment")) {
            window.setTimeout(function () {
                $('.faq_theme_3  .faq_title').click();
            }, 200);
        }

        if (~window.location.href.indexOf("#retours_possibles")) {
            window.setTimeout(function () {
                $('.faq_theme_5 .faq_title').click();
            }, 200);
        }

        if (~window.location.href.indexOf("#possible_returns")) {
            window.setTimeout(function () {
                $('.faq_theme_5  .faq_title').click();
            }, 200);
        }

        $('.footer_content .col_title').on('click touch', function () {
            var colTitle = $('.footer_content .col_title');
            var colContent = $('.footer_content .col_content');
            var mainTitle = $(this);
            var mainContent = $(this).next('.col_content');

            if (mainContent.hasClass('displayed')) {
                mainContent.slideUp(300, function () {
                    mainContent.removeClass('displayed');
                });

                mainTitle.removeClass('actif');
            } else {
                colContent.slideUp(300, function () {
                    colContent.removeClass('displayed');
                });
                mainContent.slideDown(300, function () {
                    mainContent.addClass('displayed')
                });

                colTitle.removeClass('actif');
                mainTitle.addClass('actif');
            }
        });

        $('.mob-filter-cta').on('click', function () {
            $('#filters_menu .filters').overlayScrollbars({
                overflowBehavior: {
                    x: 'hidden',
                },
            });
        });

        if ($('.asso_product_carousel_new.swiper-container').length) {
            var $this = $('.asso_product_carousel_new.swiper-container');
            var asso_product_carousel_new = new Swiper($this[0], {
                slidesPerView: 2,
                spaceBetween: 8,
                pagination: $this.find('.swiper-pagination')[0],
                paginationClickable: true,
                nextButton: $this.find('.swiper-button-next')[0],
                prevButton: $this.find('.swiper-button-prev')[0]
            });
        }

        if ($('.bloc_sub_categ .swiper-slide').length > 2) {
            var tgSwiper = new Swiper('.bloc_sub_categ', {
                slidesPerView: 'auto',
                spaceBetween: 20,
                nextButton: '.sub_categ_wrapper .swiper-button-next',
                prevButton: '.sub_categ_wrapper .swiper-button-prev'
            });
        } else {
            $('.sub_categ_wrapper .swiper-button-next').addClass('disabled');
            $('.sub_categ_wrapper .swiper-button-prev').addClass('disabled');
        }


        if (('.wrapper_seo_liens .swiper-slide').length > 2) {
            var tgSwiper = new Swiper('.wrapper_seo_liens', {
                slidesPerView: 'auto',
            });
        }

        if ($('.payez_en_3_fois_sans_frais').length) {
            $('.payez_en_3_fois_sans_frais').slideDown(500);
        }

        // Function to remove leading <br> tags
        function removeLeadingBr(html) {
            return html.replace(/^(<br\s*\/?>)+/i, '');
        }

        if ($('.seo_txt_brut').length && $('.seo_txt_brut').html().trim() !== "") {
            var maxLength = 500;
            var $seoTxtBrut = $(".seo_txt_brut");

            $seoTxtBrut.each(function () {
                var text = $(this).html();

                text = removeLeadingBr(text);
                $(this).html(text);

                if (text.length > maxLength) {
                    var visibleText = text.substr(0, maxLength);
                    var hiddenText = text.substr(maxLength);

                    $(this).html(visibleText + '<span class="more-ellipses">... </span><span class="more-text">' + hiddenText + '</span>');
                    $(this).next('.readmore').show();
                    $(".more-text").hide();
                } else {
                    $(this).next('.readmore').hide();
                }
            });

            $(".readmore").click(function () {
                var $this = $(this);
                var isExpanded = $this.data("expanded");
                var see_more = Translator.translate('see_more');
                var see_less = Translator.translate('see_less');

                if (isExpanded) {
                    $seoTxtBrut.find(".more-text").slideUp();
                    $seoTxtBrut.find(".more-ellipses").show();
                    $this.html(see_more);
                    $this.data("expanded", false);
                } else {
                    $seoTxtBrut.find(".more-text").slideDown();
                    $seoTxtBrut.find(".more-ellipses").hide();
                    $this.html(see_less);
                    $this.data("expanded", true);
                }
            });

            $(".readmore").data("expanded", false);
        }

    }, 1000);

    if (('.pushProductHome.swiper-container').length) {
        var tgSwiper = new Swiper('.pushProductHome.swiper-container', {
            slidesPerView: 2,
            spaceBetween: 8,
            pagination: '.tg-module.swiper-pagination',
            paginationClickable: true,
            nextButton: ".swiper-button-next",
            prevButton: ".swiper-button-prev",
        });
    }

    // COVER MODULE SWIPER
    if ($('#cover_module_swiper .swiper-wrapper').length) {
        if ($('#cover_module_swiper .swiper-slide .daily_video').length > 0) {
            const videoPromises = [];

            $("#cover_module_swiper .daily_video").each(function() {
                // if we try to make dynamic dailymotion video slide duration, we need to get the api
                // replace iframe with div to emebed dailymotion video and get api
                var iframe = $(this); // Get the iframe ID
                var iframeId = iframe.attr("id");
                var videoId = iframeId.replace('daily_video_', ''); // Extract the video ID (assuming format "daily_video_<video_id>")
                const videoIdDM = videoId;
                const url = `https://api.dailymotion.com/video/${videoIdDM}?fields=duration`;

                // Create a promise for each video duration fetch from Dailymotion API
                const videoPromise = fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        var videoDurationRaw = data.duration;
                        var videoDurationFormated = videoDurationRaw * 1000;
                        iframe.parents('.swiper-slide').attr('data-swiper-autoplay', videoDurationFormated);
                    })
                    .catch(error => {
                        console.error('Error fetching video details:', error);
                    });

                videoPromises.push(videoPromise);
            });

            // Wait for all video durations to be fetched before initializing the swiper
            Promise.all(videoPromises).then(() => {
                initCoverModuleSwiper();
            });
        } else {
            initCoverModuleSwiper();
        }
    }

    // My account pages swiper
    if ($('.wrapper_menu_compte').length) {
        $('.wrapper_menu_compte div:not(.greet)').wrapAll('<div id="compte_links" class="swiper swiper-container"><div class="swiper-wrapper"></div></div>');

        $('.wrapper_menu_compte div:not(.greet)').each(function () {
            $(this).addClass('swiper-slide');
        });

        setTimeout(function () {
            if ($('#compte_links').length) {
                var accSwiper = new Swiper('#compte_links', {
                    slidesPerView: 'auto',
                    spaceBetween: 20
                });

                if ($('#compte_links .actif').index() > 3) {
                    accSwiper.slideTo($('#compte_links .actif').index());
                }
            }
        }, 500);

        setTimeout(function () {
            $('.wrapper_menu_compte').addClass('show');
        }, 550);
    }

    // Login page title
    if (window.location.href.indexOf("from=vp") == -1 && $('.body_login .acc_login_title').length) {
        var my_account_log = Translator.translate('my_account_log');

        $(".body_login .acc_login_title").html(my_account_log);
        $(".body_login .acc_login_title").css('visibility', 'visible');
    } else {
        $(".body_login .acc_login_title").addClass('vp');
    }

    sticky_filters();
    initItemSlider(false);

    // Manage share cart position
    if ($('#cart_total').length && $('.share_cart').length) {
        $('.share_cart').insertBefore('#cart_total');
    }

    // Switch between cart and wishlist view in basket
    initToggleCartTypeBtn();

    // Moving order recap to the top of the page
    if ($('.total_produit.toggle').length && $('.total_produit_dropdown').length && $('#appContainer').length) {
        var toggler = $('.total_produit.toggle');
        var dropDwon = $('.total_produit_dropdown');
        var target = $('#appContainer');

        // Saving product count
        var nbProd = toggler.find('.nb_products');

        // Changing title of toggler
        toggler.find('label').text(Translator.translate('total_product_02')).append(nbProd);

        // Moving to the top of the page
        dropDwon.prependTo(target);
        toggler.prependTo(target);

        // Wraping in one div
        $('.total_produit.toggle, .total_produit_dropdown').wrapAll('<div class="wrap_cart_recap"></div>');

        // Moving addresse to the top of the page "livraison"
        if ($('#blocs_address').length) {
            $('#blocs_address').insertAfter($('.wrap_cart_recap'));
        }

        // Moving addresse to the top of the page "paiement"
        if ($('.wrapper_payment').length) {
            $('.wrapper_payment').insertAfter($('.wrap_cart_recap'));
        }
    }

    // Moving card owner name next to card logo on the page "paiement"
    if ($('body').hasClass('checkout') && $('.alias_list .alias').length) {
        $('.alias_list .alias').each(function () {
            var cardHolder = $(this).find('.card_numbers');

            $(this).find('.card_type').append(cardHolder);
        });
    }

    // Toggle product details in cart right column
    $(".total_produit.toggle").on("click", function () {
        $(this).toggleClass("open");
        $(".total_produit_dropdown").slideToggle();
    });

    if ($('.body_login.cart .login_bloc .login_form_wrapper #subtitleLogin').length) {
        var newlogin_txt_3 = Translator.translate('newlogin_txt_3');

        $(".body_login.cart .login_bloc .login_form_wrapper #subtitleLogin").html(newlogin_txt_3);
    }

    if ($('.cart .wrapper_panier .basket_gondole').length) {
        $('.cart .wrapper_panier .basket_gondole').appendTo('#contents_wrapper');
    }

    if ($('.wrapper_panier .product_sfs_warning_txt').length) {
        $('.wrapper_panier .product_sfs_warning_txt').prependTo('.left_side');
    }

    // Guides des tailles
    $(document).on("click", ".guide_feats_wrapper .thumblnk", function () {
        var nextContent_gdt = $(this).next('.thumbslide');

        $('.guide_feats_wrapper .thumblnk').not(this).removeClass('actif');
        $('.guide_feats_wrapper .thumblnk').not(this).next('.thumbslide').removeClass('actif').slideUp();

        if ($(this).hasClass('actif')) {
            $(this).removeClass('actif');
            nextContent_gdt.removeClass('actif').slideUp();
        } else {
            $(this).addClass('actif');
            nextContent_gdt.addClass('actif').slideDown();
        }

        $('#sizeguideBox .rollover_gdt_content').overlayScrollbars({
            overflowBehavior: {
                x: 'hidden',
            },
        });
    });

    // TG cart
    if ($('.cart .basket_gondole').length) {
        $('.basket_gondole .swiper-slide.item_container').wrapAll('<div class="swiper swiper-container basket_gondole_swiper"><div class="swiper-wrapper"></div></div>');

        // Add pagination
        $('.basket_gondole').append('<div class="tg-module swiper-pagination" />');

        // TG Swiper
        if ($('.basket_gondole .swiper.swiper-container').length) {
            var panierSwiper = new Swiper('.basket_gondole .swiper.swiper-container', {
                slidesPerView: 2,
                spaceBetween: 8,
                pagination: ".tg-module.swiper-pagination",
                paginationClickable: true,
            });
        }
    }

    $('body').on('click', '#cancel_button', function () {
        $('#cancel_button').next('.loader').addClass('loading');

        setTimeout(function () {
            $('#cancel_button').next('.loader').removeClass('loading');
        }, 500);
    });

    setTimeout(function () {
        if ($('#item_add_form #bloc_add_alert').length) {
            $('#item_add_form #bloc_add_alert').hide();
        }
    }, 100);

    $('.cta_size_guide').on('click', function () {
        openMultiShad('sizeguideBox');
    });

    setTimeout(function () {
        if ($('.home-module-body .home-module-cta').length) {
            $('.home-module-body .home-module-cta').addClass('appear');
        }

        if ($('.home-module-body .home-module-title').length) {
            $('.home-module-body .home-module-title').addClass('appear');
        }
    }, 200);

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function () {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if ($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function () {
        if ($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
                $('.misc_cookiesinfo #shadCookie').removeClass('actif');
            }, 500);
        }
    });
});

// ORDERS
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {
    var divId =
        "popup_numero_retour_" + ((multiTransp != undefined && multiTransp != 0) ? idOrderTransp : idOrder);

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);

    // Open shad
    openMultiShad(divId);

    $("html, body").animate({scrollTop: 0}, speed * 2, function () {
    });
}

function showPdfEtiquetteRetour(
    idOrder,
    multiTransp,
    numRetour,
    show_pdf,
    idOrderTransp
) {
    var idCmdOrder = (multiTransp != undefined && multiTransp != 0) ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $("#return_products_form_" + idCmdOrder).serialize();
        data +=
            "&idOrder=" +
            idCmdOrder +
            "&multiTransp=" +
            multiTransp +
            "&return_number=" +
            numRetour;
    } else {
        data =
            "idOrder=" +
            idCmdOrder +
            "&multiTransp=" +
            multiTransp +
            "&return_number=" +
            numRetour;
    }

    $.ajax({
        url: path_relative_root + "ajax_create_etiquette.php",
        type: "post",
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == "false") {
                alert(
                    Translator.translate(
                        "ajax_sprintf.php?arg1=js_error_return_label"
                    )
                );
            } else {
                closeMultiShad("popup_numero_retour");
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        },
    });
}


function loadProduct(type_filter, id, type_page, itm_rr_id) {
    var tmp_qte = $("#qteProd_" + id).val();
    var is_wishlist = false;
    var is_achat_express_v2 = this.form !== undefined && this.form.elements["achatExpressV2"] !== undefined && !is_wishlist;
    var triggerType = type_filter;
    var this_itm_id_val = $(this).parent('.form_itm.color').find('input[type="hidden"]').val();

    if (typeof (type_page) == 'undefined') {
        type_page = 'product';
    }

    if (typeof id == "undefined") {
        id_prod = "";
    } else {
        id_prod = "_" + id;
    }

    if (typeof type_page == "undefined") {
        type_page = "product";
    }

    var $form = "";
    var data = "";

    if (!is_achat_express_v2) {
        $form = $("#item_add_form");
        var $input = $("#prod_" + type_filter + id_prod, $form);
        var type = type_filter;
        var $hidden = $("#prod_" + type_filter + id_prod, $form);

        if (typeof id == "undefined") {
            data += $form.serialize();
        }

        if (type_filter === 'taille') {
            var couleur_id = $("#prod_couleur").val();
            var taille_id = $(this).data('sizeid');
        } else {
            var couleur_id = $(this).data('colorid');
            var taille_id = $("#prod_taille").val();
        }

        $produit_id_new = $("#prod_" + type_filter + id_prod).find(":selected").attr("data-produitid");

        if ($produit_id_new == undefined) {
            $produit_id_new = $("#prod_" + type_filter + id_prod).find(":checked").attr("data-produitid");
        }

        data += "&couleurProd=" + couleur_id;
        data += "&tailleProd=" + $("#prod_taille").val();
        data += "&type=" + type_filter;
        data += "&type_page=" + type_page;

        if (document.getElementById("wishlist")) {
            data += "&type_page=" + type_page;
        }

        if (typeof id != "undefined") {
            data += "&couleurProd=" + $("#couleurProd" + id_prod).val();
            data += "&tailleProd=" + $("#tailleProd" + id_prod).val();
            data += "&produit_principal=" + $("#produit_principal" + id_prod).val();
            var produit_principal = $("#produit_principal" + id_prod).val();
        }

        data += "&produit_id_new=" + $produit_id_new;
        var elemClass = $(".reloadProduct") ? ".reloadProduct" : ".swiper-slide";
        var div_produit_achat_express_v2 = $(this).parents(elemClass);

    } else {
        var elemClass = $(".reloadProduct").length ? ".reloadProduct" : ".swiper-slide";
        var div_produit_achat_express_v2 = $(this).parents(elemClass);
        var swiper_achat_express_v2 = $(this).parents(".swiper-container");

        $form = $(this.form);
        var id_prod = "";
        $form = this.form;

        var old_id = this.form.elements["produit_id"].value;
        var isCategSuper = this.form.elements["isCategSuper"].value;
        var new_id = id;

        itm_rr_id = itm_rr_id !== "" ? "_" + itm_rr_id.split("_")[1] : "";
        var cpt_prod = itm_rr_id !== "" ? itm_rr_id.replace("_", "") : "";
        var couleur_id = 0;

        couleur_id = $(this).val();

        data += "produit_id=" + old_id;
        data += "&produit_principal=" + old_id;
        data += "&couleurProd=" + couleur_id;
        data += "&tailleProd=";
        data += "&qteProd=1";
        data += "&hdn_product_id=" + old_id;
        data += "&produit_id_new=" + new_id;
        data += "&type=" + type_filter;
        data += "&type_page=" + type_page;
        data += "&is_achat_express=0";
        data += "&is_achat_express_v2=1";
        data += "&cpt_prod=" + (cpt_prod - 1);
        data += "&isCategSuper=" + isCategSuper;
    }

    if (isGlobalEActive) {
        data += 'warehouse_only=true';
    }

    let parent_block = $($form).closest('.swiper-wrapper');

    $.ajax({
        type: "post",
        url: path_relative_root + "ajax_get_product_regroup_ref.php",
        data: data,
        beforeSend: function () {
            $("#bloc_btn_loader").show();
            $("#btn_error").fadeOut(300);
        },
        success: function (response) {
            try {
                response = $.parseJSON(response);
            } catch (e) {
                console.error("parseJSON");
                return;
            }

            if (response.success == "ok") {
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        event: "view_item",
                    });
                }

                if (triggerType == 'taille' && window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'changeSize',
                        'produit': {
                            'size_value': datas.product.size.nom,
                            'ean': datas.product.ean
                        }
                    });
                }

                var datas = response.datas;

                if (type_page == "product") {
                    var $html_main = $(datas.html_main);

                    if (is_achat_express_v2) {
                        // Photo et information
                        div_produit_achat_express_v2.replaceWith($html_main);

                        if (
                            $("#oneProduct") != undefined &&
                            $("#oneProduct").hasClass("actif")
                        ) {
                            $(".itm_achat_express_" + new_id).addClass("full");
                        }

                        if (swiper_achat_express_v2) {
                            if (typeof four_images != "undefined") {
                                enableSwiperFourImage();
                            } else {
                                var id_swiper = swiper_achat_express_v2.attr("id");
                                refreshSliderAchatExpress(id_swiper);
                            }
                        }

                        setTimeout(function () {
                            initItemSlider(false);
                        }, 200);

                        $("#shade").removeClass("isExpress visible");
                    } else {
                        var $fiche = $("#contents_wrapper");
                        var replace_main = new Array(
                            "#wrapper_product_name",
                            "#zoom_container",
                            ".prod_page_bot",
                            ".associations_section",
                            ".accordion_container",
                            ".cms-page-module",
                            "w-netreviews-stars",
                            "w-netreviews-comments",
                            ".netreviews_reviews",
                            "#item_description",
                        );

                        var product_refext_prod = $("input[name=itm_refext_prod]", $html_main).val();

                        for (var i = 0; i < replace_main.length; i++) {
                            var id = replace_main[i];

                            if (id == "w-netreviews-stars") {
                                // New Avis verifies
                                $("#w-netreviews-stars", $fiche).html(
                                    $("w-netreviews-stars", $html_main).html()
                                );
                                // Reload vuejs avis verifies
                                if (
                                    typeof wShop !== "undefined" &&
                                    wShop.$refs.wNetreviewsStars
                                ) {
                                    // Avis verifies stars
                                    if (
                                        wShop.$refs.wNetreviewsStars !=
                                        "undefined"
                                    ) {
                                        wShop.$refs.wNetreviewsStars.reload(
                                            product_refext_prod
                                        );
                                    }
                                }
                            } else if (id == "w-netreviews-comments") {
                                // New Avis verifies
                                $("#w-netreviews-comments", $fiche).replaceWith(
                                    $("w-netreviews-comments", $html_main).wrap("<p/>").parent().html()
                                );
                                // Reload vuejs avis verifies
                                if (
                                    typeof wShop !== "undefined" &&
                                    wShop.$refs.wNetreviewsComments
                                ) {
                                    // Avis verifies reviews
                                    if (wShop.$refs.wNetreviewsComments != "undefined") {
                                        wShop.$refs.wNetreviewsComments.reload(product_refext_prod);
                                    }
                                }
                            } else {
                                // In case info is not present in response, remove it (ex: cms-page-module on one ref but not on an other)
                                if ($(id, $fiche).length && $(id, $html_main).length) {
                                    $(id, $fiche).html($(id, $html_main).html());
                                } else {
                                    if ($(id, $fiche).length && !$(id, $html_main).length) {
                                        $(id, $fiche).replaceWith("");
                                    }
                                }
                            }
                        }

                        // depending on type_filter, update either color checked or size selected
                        if (type_filter === 'taille') {
                            $('#prod_taille [value=' + taille_id + ']').prop('selected', true);
                        }

                        if (type_filter === 'couleur') {
                            $('#prod_couleur #color_' + couleur_id).prop('checked', true);
                        }

                        /* ASSOCIATED PRODUCTS */
                        var slides_per_view =
                            typeof associatedProduct_spv != "undefined"
                                ? associatedProduct_spv
                                : 3;
                        var asso_btn_prev =
                            typeof assoBtnPrev != "undefined"
                                ? assoBtnPrev
                                : ".swiper-button-prev";
                        var asso_btn_next =
                            typeof assoBtnNext != "undefined"
                                ? assoBtnNext
                                : ".swiper-button-next";
                        var asso_space_between =
                            typeof assoSpaceBetween != "undefined"
                                ? assoSpaceBetween
                                : 20;
                        var asso_swiper_params =
                            typeof assoSwiperParams != "undefined"
                                ? assoSwiperParams
                                : {
                                    slidesPerView: slides_per_view,
                                    spaceBetween: asso_space_between,
                                    nextButton: asso_btn_prev,
                                    prevButton: asso_btn_next,
                                };

                        window.prod_slider = document.getElementById("product_pictures_slider");
                        window.asso_slider = document.getElementById("asso_product_carousel");

                        //set slider product
                        if (
                            prod_slider &&
                            $(".swiper-slide", prod_slider).length > 1
                        ) {
                            var slidesPerView = $("#zoom_container").data("slidesperview");
                            var spaceBetween = $("#zoom_container").data("spacebetween");

                            if (slidesPerView == undefined) {
                                slidesPerView = 1;
                            }

                            if (spaceBetween == undefined) {
                                spaceBetween = 0;
                            }

                            var prodSwiperSlider = new Swiper("#zoom_container .swiper-container", {
                                    slidesPerView: slidesPerView,
                                    mode: "horizontal",
                                    centeredSlides: true,
                                    pagination: ".swiper-pagination",
                                    paginationClickable: true,
                                    preloadImages: true,
                                    lazyLoading: true,
                                    spaceBetween: spaceBetween,
                                    loop: true,
                                    nextButton: ".swiper-button-next",
                                    prevButton: ".swiper-button-prev",
                                }
                            );
                        }

                        var string_params = "";

                        if ($("#produit_lot_nb").val() != undefined) {
                            string_params += "&produit_lot_nb=" + $("#produit_lot_nb").val();
                        }

                        if ($("#tailleProd").val() > 0) {
                            // RECHARGEMENT QTE
                            var qte = ajax_file(path_relative_root + "ajax_liste_qte.php?idprod=" + datas.product.id + "&idcouleur=" + datas.product.color.id + "&idtaille=" + $("#tailleProd").val() + string_params);
                            $("#liste_qte").html(qte);
                        }

                        // If we have one size we directly check the stock of the product (cf VB)
                        if ($('input[name="itm_one_size"]').length && $('input[name="itm_one_size"]').val() === "1") {
                            onAttrSelect(parseFloat(datas.product.id));
                        }

                        var contentMovable = $("#content_movable");

                        $(".jqzoom").on("doubletap", function () {
                            zoomImg.call(this);
                            $("#zoomBox").addClass("active");

                            contentMovable.addClass("visible");
                            var zoomboxWidth = $("body").width(), scrollTo = zoomboxWidth / 2;

                            setTimeout(function () {
                                $("#zoomBox").scrollLeft(scrollTo);
                            }, "slow");
                        });

                        contentMovable.on("doubletap", function () {
                            $(this).removeClass("visible");
                            $("#zoomBox .btn_close").trigger("click");
                        });

                        //check stock product
                        var hasStock = false;
                        var stocks = response.stock;

                        if (stocks && stocks.length > 0) {
                            $.each(stocks, function (index, stock) {
                                $.each(stock, function (key, val) {
                                    if (val === true) {
                                        hasStock = true;
                                        $("#size_" + key).removeClass("disabled");
                                    } else {
                                        $("#size_" + key).addClass("disabled");
                                    }
                                });
                            });
                        }
                    }
                    if ($("#oneProduct").length && $("#oneProduct").hasClass("actif")) {
                        $("div.item_container").addClass("full");
                    }
                }

                if ($("#btn_add_alert", response.datas.html_main) && $("#btn_add_alert", response.datas.html_main).attr("onclick") && $("#btn_add_alert", response.datas.html_main).attr("onclick").indexOf("lightboxAlertStock") !== false) {
                    $(".eclat_product_nouveaute_fiche_produit").show();
                }

                $(".item_container").each(function (e) {
                    var this_item_rayon = $(this).attr('id');

                    if (!$(this).find('.prodItemSlider').hasClass('initialized') && $(this).find('.prodItemSlider .swiper-slide').length > 1) {
                        var swiper_rayon_mobile_each = new Swiper('#' + this_item_rayon + ' .prodItemSlider', {
                            roundLengths: true,
                            slidesPerView: 1,
                            loop: true,
                            lazyLoading: true,
                            preloadImages: false,
                            observer: true,
                            observeParents: true,
                            pagination: '#' + this_item_rayon + ' .itemSliderPagination',
                        });

                        $(this).find('.prodItemSlider').addClass('initialized');
                    }
                });

                // BEGIN Apple Pay Express Checkout Product
                if (typeof initApplePayExpressCheckout == 'function') {
                    initApplePayExpressCheckout();
                }
                // END Apple Pay Express Checkout Product
                
            } else {
                $("#btn_error").fadeIn();
            }

            if (document.getElementById("alma-widget") !== null) {
                initAlmaWidget();
            }

            // Créate CustomEvent "loadProduct_success" to succes LoadProduct
            var evt = new CustomEvent("loadProduct_success");
            window.dispatchEvent(evt);
        },
        complete: function () {

            if ($('.rollover.achat_express').is(':visible')) {
                hidePopinColorSize();
            }

            if (window.lazyload) {
                lazyload.init();
            }

            lazyloadImage();

            var form = document.getElementById("item_add_form");

            if (is_achat_express_v2) {
                form = $form;
            } else {
                colors = form.itm_colour;
                updateColor.call(colors.options[colors.selectedIndex]);
            }

            setTimeout(function () {
                $(".zoom_indicator").fadeOut(800);

                if ($('.asso_product_carousel_new.swiper-container').length) {
                    var $this = $('.asso_product_carousel_new.swiper-container');
                    var asso_product_carousel_new = new Swiper($this[0], {
                        slidesPerView: 2,
                        spaceBetween: 8,
                        pagination: $this.find('.swiper-pagination')[0],
                        paginationClickable: true,
                        nextButton: $this.find('.swiper-button-next')[0],
                        prevButton: $this.find('.swiper-button-prev')[0]
                    });
                }
            }, 2500);

            if (type_page == "product") {
                $("#ctaAvailabilityInStoreTitle").html(Translator.translate('availabilityInStore'));
            }

            // Create CustomEvent "loadProduct_complete" to complete LoadProduct
            var evt = new CustomEvent("loadProduct_complete");
            window.dispatchEvent(evt);

            // WPOS Exclusive - Get if product is available in store use in WPOS
            var idStore = getCookie('wshopStoreId');
            var idProduct = getCookie('p_id');
            if (idStore != undefined && idStore > 0) {
                var color = $("#prod_couleur").val();
                var size = $("#prod_taille").val();
                getProductInfoByStore(idProduct, color, size, idStore)
            }

            $(parent_block).css({"left": ""});

            if (this_itm_id_val !== null && this_itm_id_val !== undefined) {
                if ($('#i' + this_itm_id_val).hasClass('full')) {
                    setTimeout(function () {
                        $('html, body').animate({
                            scrollTop: $('#i' + this_itm_id_val).offset().top - 100
                        }, 0);
                    }, 2500);
                }
            }
        },
    });

    if ($('.rollover.achat_express.size').is(':visible')) {
        hidePopinColorSize();
    }
}

function sticky_filters() {
    $(window).scroll(function () {
        var menu_sticky = $(".filtersArea");
        var header_rayon = $('.banner_wrapper');
        var header_rayon_height = header_rayon.height();
        var btn_up = $('#btt_button');

        if ($(".seo_wrapper").length) {
            var txt_seo_height = $('.seo_wrapper').height();
        } else {
            var txt_seo_height = 0;
        }

        if ($(".sub_categ_wrapper").length) {
            var sub_categ_wrapper = $('.sub_categ_wrapper').height();
        } else {
            var sub_categ_wrapper = 0;
        }

        var menu_sticky_height = header_rayon_height + menu_sticky.height() + txt_seo_height + sub_categ_wrapper + 150;

        if ($(this).scrollTop() > menu_sticky_height) {
            menu_sticky.addClass('sticky').css({'top': header_rayon_height});
            btn_up.addClass('vissible');
        } else {
            menu_sticky.removeClass('sticky').css({'top': header_rayon_height});
            btn_up.removeClass('vissible');
        }

        setTimeout(function () {
            var $swiper_container_no_results = $('.no_results_wrapper .pushProductHome.swiper-container:not(.swiper-container-android):not(.swiper-initialized)');

            if ($swiper_container_no_results.length) {
                $swiper_container_no_results.each(function (index, element) {
                    var tgSwiper2 = new Swiper(element, {
                        slidesPerView: 2,
                        spaceBetween: 8,
                        pagination: '.tg-module.swiper-pagination',
                        paginationClickable: true,
                        nextButton: '.swiper-button-next',
                        prevButton: '.swiper-button-prev',
                    });
                    // Ajouter une classe pour marquer cet élément comme initialisé
                    $(element).addClass('swiper-initialized');
                });
            }

            $('.banner_nav .cache').removeClass('cache');
        }, 2000);
    });
}

function initItemSlider(isToggled) {

    if ($('.prodItemSlider, .searchItemSlider').length) {

        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php

        if (isToggled == true) { // Toggle view is triggerred

            $('.prodItemSlider, .searchItemSlider').css('opacity', '0');

            setTimeout(function () {
                $(".prodItemSlider, .searchItemSlider").each(function () {
                    if ($(this).hasClass("initialized")) {
                        this.swiper.update();
                    }
                });

                $('.prodItemSlider, .searchItemSlider').css('opacity', '1');

            }, 500); // 300 equals the css transition timing defined on .item_container
        } else { // From page load and scroll

            $(".prodItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass("initialized")) {

                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        preloadImages: false,
                        lazyLoading: true,
                        observer: true,
                        observeParents: true,
                        pagination: ".itemSliderPagination",
                    });

                    $(this).addClass("initialized");
                }
            });

            $(".searchItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass("initialized")) {
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        preloadImages: false,
                        lazyLoading: true,
                        observer: true,
                        observeParents: true,
                        pagination: ".itemSliderPagination",
                    });

                    $(this).addClass("initialized");
                }
            });
        }
    }
}

/* Load global .item_container slider initialization on window scroll*/
window.addEventListener("scroll", function () {
    initItemSlider(false);
});

/* Initialisation des JS pour la recherche V2 */
function triggerFunctionOnSearchEnd() {
    //Init des swiper dans le cas des visuel multi des produit (MOBILE_MULTI_VISUALS)
    initItemSlider(false);
}

function toggleViewMobile() {
    $('div.toggleNumObjects span, div.toggleNumObjects button').toggleClass('actif');

    if ($("#oneProduct").hasClass('actif')) {
        $('.twoProducts').removeClass('actif');
        $('div.item').addClass('full');
        $('div.list_item').addClass('full');
        createCookie('nb_produits', 1, 1);
    } else {
        $('div.item').removeClass('full');
        $('div.list_item').removeClass('full');
        $('.oneProduct').removeClass('actif');
        createCookie('nb_produits', 2);
    }

    initItemSlider(true);
}

// WISHLIST
// add product to basket from wishlist (connected)
function moveProductFromWishlistToCart(elemBtn) {

    $(elemBtn).addClass('processing');
    var prodId = $(elemBtn).attr("data-produit-id");
    var colorId = $(elemBtn).attr("data-color-id");
    var sizeId = $(elemBtn).attr("data-size-id");

    let datas = {
        data: $(elemBtn).attr("data-id"),
    };

    if (prodId !== undefined) {
        datas.productId = prodId;
    }

    if (colorId !== undefined) {
        datas.colorId = colorId;
    }

    if (sizeId !== undefined) {
        datas.sizeId = sizeId;
    }

    $.post(path_relative_root + "ajax_add_panier_wishlist.php", datas, function (resp) {
        if (resp === "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}


function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop) {

    if ($('.see_all')) {
        $('.see_all').addClass('loading');
    }

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        nb_product = nb_product || parseFloat($('#nb_prod').val()),
        type = form.getValue("type"),
        idObj = idObj || parseFloat($('#idObj').val()),
        startPage = parseInt($('#page_start').val());


    if (fromScrollBottom && !fromScrollTop) {
        $("#page_start").val(startPage + 1);

        $("#initial_page").val(page + 1);
    }

    output = triggerFilter(page, type, idObj, nb_product, "filters_form");

    let one_product = $("#oneProduct").hasClass("actif") ? true : false;

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        // Get all current item length (because of push)
        this.page.value = page * nb_product;
        is_from_page = true;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix = $("#amount1").val() + "~" + $("#amount2").val();
    }

    if ($("input[name=tri]:checked").val() != undefined) {
        output.tri = $("input[name=tri]:checked").val();
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    //Handle pagination with push images
    output.page = fromScrollBottom ? $("#scroll_items .reloadProduct").length : 0;
    output.handle_push = 'false';

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_reload_products.php',
        type: 'get',
        data: output,
        success: function (res) {

            var old_elm = document.querySelector('.list_item');
            var content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {
                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();

            itm_length = parseFloat(res[1]);

            // This block of code updates the SEO-related link elements in the HTML document.
            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

            $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);
            $("link[rel^='alternate'][hreflang^='x-default']").attr("href", JSON.parse(res[8]).en);

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
            changeSeoElement(next, hrefNext, commentNext, 'next');

            if ($('.rayonCategSlider').length && output.object_id) {

                $('.rayonCategSlider .menuitem').removeClass('actif');
                $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                var rayonInitialSlide;

                $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                    if ($(obj).hasClass('actif')) {
                        rayonInitialSlide = i;
                    }
                });

                rayonCategSlider.slideTo(rayonInitialSlide);
            } else {
                $('.rayonCategSlider .menuitem').removeClass('actif');
            }

            if (content !== '') {
                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;
                let nbProductLabel = (itm_length > 1 ? Translator.translate('2_product') : Translator.translate('one_product'));
                $('.seo_nb_product').html(itm_length + ' ' + nbProductLabel);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //L'inverse pour le bas
                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (one_product) {
                    $('div.item_container').addClass('full');
                }

                if (parseFloat($('#filters_menu').css('right')) === 0) {
                    toggleMenu('filters', 'right');
                }

                if (window.lazyload || typeof lazyload != 'undefined') {
                    lazyload.init();
                }

                lazyloadImage();
            }
        },
        complete: function () {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');

            if (!fromScrollBottom && !fromScrollTop) {
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: ($(window).scrollTop() + 10)
                    }, 200);
                }, 250);
            }

            $('#is_loading').val('0');

            if (fromScrollTop || fromScrollBottom) {
                /**
                 * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                 * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                 */

                if ($('body.category').length) {
                    var origin = $('.item_container:not(.push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();
                }
            }

            /**
             * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
             */
            var productLoaded = $('#scroll_items .reloadProduct').length;
            var total_element = document.getElementById('totalElems').value;
            if (productLoaded > total_element) {
                productLoaded = total_element;
            }
            var percentLoaded = (productLoaded * 100 / total_element);

            if (productLoaded > total_element) {
                $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, productLoaded));
            } else {
                $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, total_element));
            }

            $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

            if (productLoaded >= total_element) {
                window.setTimeout(function () {
                    $('.loader_scroll').removeClass('loading');
                }, 2000);

                $('.see_all_product').hide();
            } else {
                $('.loader_scroll').addClass('loading');
                $('.see_all_product').show();
            }

            // Init product sliders
            initItemSlider(false);

            initDailymotionVideos();
        }
    });
}

function generateNewBlocFilter(type, idObj, page, nb_product, btn, typeFiltre, id) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        value = getInputVal.bind(this),
        output = {},
        tmpoutput = {},
        is_from_page = false,
        key,
        page = (page !== undefined) ? page : 0;

    var thisform = form;

    // Get all filters
    form = Array.prototype.slice.call(form.elements, 0);
    var keys = new Array();

    output = triggerFilter(page, type, idObj, nb_product, 'filters_form');

    output.page = 0;

    /** LEGACY **/

    //Recuperation du trie par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // Si il y a un ordre dans les prix on charge l'id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        var tri = res[1][0];
    }

    /** END LEGACY **/

    if (!tri) {
        output.tri = tri;
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    // For better ajax response, execute these two lines
    // output.handle_push = 'false';

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_change_filtre_produit.php',
        type: 'get',
        data: output,
        success: function (response) {

            var resultats = jQuery.parseJSON(response);

            var typeFilter = resultats.filter;
            var array_size = resultats.size;
            var array_colors = resultats.color;
            var array_brand = resultats.brand;
            var array_eclat = resultats.eclat;
            var array_store = resultats.store;

            if (typeFilter == 'sscat' && $('.container_products_lookbook').length == 0) {
                if (array_colors.length == 0 || array_size.length == 0) {
                    changeajaxfilterRayon(thisform, 'all', id, true);
                    return true;
                }
            }

            if (typeFilter != 'taille') {
                generateListFilter('taille', array_size, true);
            }

            if (typeFilter != 'couleur') {
                generateListFilter('couleur', array_colors, true);
            }

            if (typeFilter != 'brand') {
                generateListFilter('promo', array_brand, true);
            }

            if (typeFilter != 'eclat') {
                generateListFilter('eclat', array_eclat, true);
            }

            if (typeFilter != 'store') {
                generateListFilter('store', array_store, true);
            }

            /* Nombre de produits */
            // Get all filters
            form = Array.prototype.slice.call(thisform.elements, 0);
            // Build an object to store active filters
            form.forEach(function (elm) {
                if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                    if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                        if (tmpoutput[elm.name] === undefined) {
                            tmpoutput[elm.name] = [];
                        }

                        if (elm.name == 'charact') {
                            tmpoutput[elm.name].push(elm.value);
                        } else {
                            tmpoutput[elm.name].push(parseFloat(elm.value));
                        }
                    }
                }
            });

            // Convert filters arrays to strings
            for (key in keys) {
                if (tmpoutput.hasOwnProperty(key)) {
                    output[key] = tmpoutput[key].join('~');
                } else {
                    delete output[key];
                }
            }

            if ($(".filter_date").length > 0) {

                output.multi_object = 'true';
                output.id_category_super = id_category_super;

                if (typeof (output['date']) == 'undefined') {
                    output.date = 4;
                }
                else {
                    if (output['date'] == 4)
                        output['date'] = tmp_date;
                }
            }
            else {
                if (output['date'] == 4) {
                    output['date'] = tmp_date;
                }
            }

            bloc_prod_xhr = $.ajax({
                url: path_relative_root + 'ajax_reload_products.php',
                type: 'get',
                data: output,
                success: function (res) {
                    var old_elm = document.getElementById('scroll_items');

                    res = res.split('><><');

                    var content = res[0].trim();

                    if (res[1] !== '') {
                        var itm_length = parseFloat(res[1]);
                        $("#scroll_items .list_item").html(content);
                        //Permet d'actualiser lors de l'application d'un filtre, le nombre de produits total restants après application
                        //Info acutalisée en haut du filters_menu - potentiellement en display:none;
                        document.getElementById('nbr_items').innerHTML = itm_length;
                        document.getElementById('totalElems').value = itm_length;

                        /**
                         * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                         */
                        var productLoaded = $('#scroll_items .reloadProduct').length;
                        var total_element = document.getElementById('totalElems').value;
                        var percentLoaded = (productLoaded * 100 / parseInt(output.nb_tot_prod));

                        if (productLoaded > output.nb_tot_prod) {
                            $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, productLoaded));
                        } else {
                            $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, parseInt(output.nb_tot_prod)));
                        }

                        $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

                        if (productLoaded >= total_element) {
                            window.setTimeout(function () {
                                $('.loader_scroll').removeClass('loading');
                            }, 2000);

                            $('.see_all_product').hide();
                        } else {
                            $('.loader_scroll').addClass('loading');
                            $('.see_all_product').show();
                        }

                        if (typeof lazyload !== "undefined") {
                            lazyload.init();
                        }
                    }
                    $('#is_loading').val('0');
                },
                complete: function () {
                    var evt = new CustomEvent("generateNewBlocFilter_complete");
                    window.dispatchEvent(evt);
                }
            });
        }
    });
}

// Initialize and filter and show corresponding questions according to user's keyword
function faqinit(array_questions) {
    $(document).on("change keyup focus", "#faq_search", function () {
        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {
            $(".search_results li").show().removeClass("hasMatch");

            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {
                val["nom"] = val["nom"].toUpperCase();

                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $(".search_results").show();
                    $("li#brand_" + val["id"]).addClass("hasMatch");
                }
            });

            $(".brand_item:not(.hasMatch)").hide();

            if (array_match.length == 0) {
                $(".search_results").hide();
            }
        } else {
            $(".search_results").hide();
            $(".search_results li").show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $(".search_results").mousedown(function () {
        click_in_process = true;
    });

    $(".search_results").mouseup(function () {
        click_in_process = false;
        $("#faq_search").focus();
    });

    $("#faq_search").blur(function () {
        if (!click_in_process) {
            $(".search_results").hide();
            $(".search_results li").show().removeClass("hasMatch");
        }
    });

    $("#theme_global .theme").on("click", function () {
        var id = $(this).data("id");
        var theme = $("#theme_page");

        $("#theme_global").removeClass("actif");
        $(".title_detail_sub").css("display", "none");
        theme.addClass("actif");
        showTabFaq(id, 1);

        $("html, body").animate(
            {scrollTop: $(".faq_theme_" + id + "").offset().top - 100},
            1000
        );
    });

    $("#theme_page .theme").on("click", function () {
        var id = $(this).data("id");

        showTabFaq(id, 1);
    });
}

function researchFaq(node_id, level, question_id) {
    $(".search_results").hide();
    $(".search_results li").show().removeClass("hasMatch");

    if ($("#theme_global").hasClass("actif")) {
        $("#theme_global").removeClass("actif");
        $("#theme_page").addClass("actif");
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }

    showTabFaq(node_id, level, question_id);

    $("html, body").animate(
        {scrollTop: $(".faq_question_" + question_id + "").offset().top - 130},
        1000
    );
}

function searchCategoryFilter() {
    window.setTimeout(function () {
        if ($("#slideCateg .swiper-slide").length > 2) {
            new Swiper("#slideCateg", {
                slidesPerView: 'auto',
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev'
            });

            $("#slideCateg").addClass('initialized');
        }

        $('.tg-module .home-module-title').click(function () {
            var module_tg_id = $(this).closest(".tg-module").attr('id');
            var tg_id = $(this).attr('id');
            var tg_title_id = tg_id.replace('-title', '');

            $("#" + module_tg_id + " .home-module-title, #" + module_tg_id + " .home-module-tg").removeClass("selected");
            $("#" + tg_id).addClass("selected");
            $("#" + tg_title_id).addClass("selected");
        });
    }, 500);
}

function close_search() {
    $('.search_trigger').removeClass('actif');
    $('#top_searchV2 #form_recherchev2').removeClass('open');
    $('#shade').removeClass('visible');
    $('body').removeClass('fixed');

    window.scrollBy(0, 2);

    setTimeout(function () {
        var $swiper_container_no_results = $('.no_results_wrapper .pushProductHome.swiper-container:not(.swiper-container-android):not(.swiper-initialized)');

        if ($swiper_container_no_results.length) {
            $swiper_container_no_results.each(function (index, element) {
                var tgSwiper2 = new Swiper(element, {
                    slidesPerView: 2,
                    spaceBetween: 8,
                    pagination: '.tg-module.swiper-pagination',
                    paginationClickable: true,
                    nextButton: '.swiper-button-next',
                    prevButton: '.swiper-button-prev',
                });
                // Ajouter une classe pour marquer cet élément comme initialisé
                $(element).addClass('swiper-initialized');
            });
        }

        $('.banner_nav .cache').removeClass('cache');
    }, 2000);
}

$(window).on('scroll', function () {

    if ($("body").hasClass("product_page") && $("#search_page").length === 0) {
        var addBasketWrapper = $('#add_basket_wrapper');
        var blocAddAlert_cta = $('#bloc_add_alert');
        var floatingBasket = $('#floating_add_basket');

        var visibleWrapper = addBasketWrapper.is(':visible') ? addBasketWrapper : blocAddAlert_cta;

        if (visibleWrapper.length) {
            var wrapperBottom = visibleWrapper.offset().top + visibleWrapper.outerHeight();
            var scrollTop = $(window).scrollTop();

            if (scrollTop > wrapperBottom) {
                floatingBasket.fadeIn();
            } else {
                floatingBasket.fadeOut();
            }
        }
    }
});

function show_lightbox_3xcb() {
    $('.alma-payment-plans-container').click();
}

// After ajax called in basket (also called in _app)
function updateCartElements() {
    // Switch between cart and wishlist view in basket
    initToggleCartTypeBtn()
}

function initToggleCartTypeBtn() {
    if ($(".toggleCartTypeBtn").length) {
        let toggleCart = $(".toggleCartTypeBtn");
        let cartContent = $(".cart_main_table.basket");
        let wishContent = $(".cart_main_table.wishlist");
        let cartSummary = $("#tunnel_right_col");

        toggleCart.on("click", function () {
            toggleCart.removeClass("actif");
            $(this).addClass("actif");
            $(".cart_main_table").removeClass("displayed");

            if ($(this).hasClass("basket")) {
                cartContent.addClass("displayed");
                cartSummary.removeClass("noDisplay");
            } else {
                wishContent.addClass("displayed");
                cartSummary.addClass("noDisplay");
            }
        });
    }
}

function showPopinColorSize(type) {
    var size_selected_select_option = $(this).find("option:checked").attr("value");
    var size_selected_popup_size = $(this).parents(".displayTwo").find(".achat_express.size .rollover_left .form_itm.size");

    $("#shade").addClass("visible isExpress");
    $(".rollover.achat_express." + type).show();
    $(".rollover.achat_express." + type).find(".ligne_form").addClass('actif');
    $('body').addClass('noscroll');

    if ($(this).hasClass("size")) {
        $(this).blur();
    } else {
        $(this).find('#prod_couleur').blur();
    }

    size_selected_popup_size.each(function () {
        if ($(this).attr("data-sizeid") === size_selected_select_option) {
            size_selected_popup_size.find("label").removeClass("selected");
            $(this).find("label").addClass("selected");
        }
    });
}

function changeColorMobile() {
    let color_Id = $(this).data('colorid');
    let color_popup = $('.rollover.achat_express.color .choices_list .form_itm');
    let produitId = this.getAttribute('data-produitid');
    let selectElement = document.getElementById('prod_couleur');
    let options = selectElement.options;

    $("#prod_couleur").val(color_Id);
    loadProduct.call(this, 'couleur');
    color_popup.removeClass("active selected");
    $(this).addClass("active selected");
    $('body').removeClass('noscroll');

    setTimeout(function () {
        $("#shade").removeClass('visible');
    }, 500);
}

function changeSizeMobile(sizeGrouped) {
    let sizeId = $(this).data('sizeid');
    let productId = $(this).data('produitid');
    let size_popup = $('.rollover.achat_express.size .choices_list .form_itm');

    $("#prod_taille").val(sizeId);
    size_popup.removeClass("active");
    $(this).addClass("active");

    if ($("#prod_taille").hasClass("error")) {
        $("#prod_taille").removeClass("error");
    }

    if (sizeGrouped) {
        loadProduct.call(this, 'taille');
    } else {
        onAttrSelect(parseFloat(productId));
    }

    $("#button_add, #button_add_float").addClass("active");

    if ($('#prod_taille').length > 0) {
        if ($('#prod_taille option:selected').hasClass('disabled')) {
            $('#prod_taille').addClass('no_stock');
        } else {
            $('#prod_taille').removeClass('no_stock');
        }
    }

    if ($(this).hasClass('disable_globale')) {
        if ($('#button_add_float').length > 0) {
            $('#button_add_float').hide();
            $('#bloc_add_alert_float').show();
        }
    } else {
        if ($('#button_add_float').length > 0) {
            $('#button_add_float').show();
            $('#bloc_add_alert_float').hide();
        }
    }

    hidePopinColorSize();
}

function hidePopinColorSize() {
    $("#shade").removeClass("visible isExpress");
    $(".rollover.achat_express").hide();
    $(".rollover.achat_express").find(".ligne_form").removeClass('actif');
    $('body').removeClass('noscroll');
}

function openColorAchatExpressV2() {
    if ($(this.form).closest('.swiper-wrapper').length) {
        let parent_block = $(this.form).closest('.swiper-wrapper');
        let move_left = $(parent_block).css('transform').split(',')[4];

        $(parent_block).css({"left": move_left + "px"});
    }

    var $div_color = $(this.form).find('.productColorFieldset');
    var $div_size = $(this.form).find('.productSizeFieldset');
    var div_color_actif = $div_color.hasClass('actif');
    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');
    var $div_alert_stock = $(this.form).find('.bloc_add_alert');

    $div_alert_stock.hide();

    $('.achat_express').hide();
    $('.productColorFieldset.actif').removeClass('actif');
    $('.productSizeFieldset.actif').removeClass('actif');

    if (lightBoxAlertStock) {
        lightbox.addClass('displayColors');
    }

    if (div_color_actif) {
        $(this.form).find('.achat_express').hide();
        $div_color.removeClass('actif');

    } else {
        $(this.form).find('.achat_express').show();
        $div_color.addClass('actif');
        $div_size.removeClass('actif');
    }
}

function hideAchatExpress() {
    if ($(this.form).closest('.swiper-wrapper').length) {
        let parent_block = $(this.form).closest('.swiper-wrapper');

        $(parent_block).css({"left": ""});
    }

    $("#shade").removeClass("visible").removeClass("isExpress");

    $(this.form).find('.achat_express').hide();

    $(this.form).find('.actif').removeClass('actif');

    $(this.form).find('.actif').removeClass('actif');

    var $div_alert_stock = $(this.form).find('.bloc_add_alert');

    $div_alert_stock.hide();

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');

    if (lightBoxAlertStock) {
        lightbox.removeClass('displayColors');
    }
}

function toggleMenuExtra() {
    $('#filters_menu .loader').removeClass('loading');
}


function checkProductInWishlist() {
    if (typeof button_add_to_wishlist_initial_click_event === "undefined") {
        button_add_to_wishlist_initial_click_event = $("#addToWishlistButton").attr("onclick");
    }

    if (typeof button_add_to_wishlist_initial_label === "undefined") {
        button_add_to_wishlist_initial_label = $("#addToWishlistButton").html();
    }

    let productId = $("#produit_id").val();
    let colorSelected = $("#prod_couleur").val();
    let sizeSelected = $("#prod_taille").val();

    if (productId !== undefined && colorSelected !== "" && sizeSelected !== "") {
        $.ajax({
            type: "get",
            url: path_relative_root + "get_user_wishlist.php",
            data: {
                product_id: productId,
                size_id: sizeSelected,
                color_id: colorSelected,
            },
            success: function (response) {
                var userWishlist = JSON.parse(response);

                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                } else {
                    changeBtnAddWishlist(false);
                }
            },
            error: function () {
                // Handle AJAX error if needed
                changeBtnAddWishlist(false);
            }
        });
    } else {
        changeBtnAddWishlist(false);
    }
}

// Search of diffrent shop depending on the filter
function rechercheProximite(latlng, not_init) {
    // for the filter
    var param = '';
    // for the search
    var param_sup = '';

    // Owe get the checkbox for the filter
    $("input[name='filtres[]']:checked").each(function () {
        param += '&filtres[]=' + $(this).val();
    });

    var adresse = document.getElementById('search_cp').value;

    if (adresse.substr(0, 20) === Translator.translate('default_address_begin')) {
        adresse = '';
    }

    param_sup += 'search=' + adresse + param;

    if (!($('body.product_page.mobile').length && removePaysRequest)) {
        param_sup += '&pays=' + encodeURIComponent(document.getElementById('search_pays').value);
        param_sup += '&pays_id=' + $("#search_pays").find(":selected").data('id');
    }

    if (document.getElementById('pdv') && document.getElementById('pdv').value !== '') {
        param_sup += '&pdv=' + encodeURIComponent(document.getElementById('pdv').value);
    }

    if (document.getElementById('produit_id') && document.getElementById('produit_id').value !== '') {
        param += '&produit_id=' + encodeURIComponent(document.getElementById('produit_id').value);
        param_sup += '&produit_id=' + encodeURIComponent(document.getElementById('produit_id').value);
    }

    // specific case catalog
    if (document.getElementById('cataloguesWrapper') && typeof (magasin_filtre_nom) && magasin_filtre_nom != '') {
        param += '&magasin_filtre_nom=' + magasin_filtre_nom;
        param_sup += '&magasin_filtre_nom=' + magasin_filtre_nom;
    } else {
        if (document.getElementById('magasin_filtre_nom')) {
            if (document.getElementById('magasin_filtre_nom').getAttribute('filtre_nom')) {
                param += '&magasin_filtre_nom=' + document.getElementById('magasin_filtre_nom').getAttribute('filtre_nom');
                param_sup += '&magasin_filtre_nom=' + document.getElementById('magasin_filtre_nom').getAttribute('filtre_nom');
            } else {
                param += '&magasin_filtre_nom=' + document.getElementById('magasin_filtre_nom').value;
                param_sup += '&magasin_filtre_nom=' + document.getElementById('magasin_filtre_nom').value;
            }
        }
    }

    type_call = 'auto';

    var lat_exact;
    var lng_exact;
    var requeteRecherche;
    var retailers_map = $('#retailers_map');

    retailers_map.css('width', '100%');

    if (typeof (latlng) != 'undefined') {
        type_call = 'button';

        if (latlng.coords || (latlng.lat && latlng.lng)) {

            if (retailers_map.length) {

                var myOptions = {
                    styles: google_map_style
                };

                var mcOptions = {
                    gridSize: 80,
                    imagePath: path_relative_root + 'img/maps/cluster'
                };

                if (typeof MapsProviderService != "undefined") {
                    var mapHtml = document.getElementById('retailers_map');
                    maCarte = MapsProviderService.initSearchShopPage(mapHtml);
                } else {
                    maCarte = new google.maps.Map(document.getElementById("retailers_map"), myOptions);
                    clusterer = new MarkerClusterer(maCarte, markers, mcOptions);
                }

                boundChanched(maCarte, markersArray);
                markers = [];
            }

            if (latlng.coords) {
                lat_exact = latlng.coords.latitude;
                lng_exact = latlng.coords.longitude;
            } else {
                lat_exact = latlng.lat;
                lng_exact = latlng.lng;
            }

            param_sup = param;
            param_sup += '&pays=' + encodeURIComponent(document.getElementById('search_pays').value);
            param_sup += '&pays_id=' + $("#search_pays").find(":selected").data('id');

            var requeteRecherche = path_relative_root + 'ajax_generate_xml_map.php?lat=' + lat_exact + '&lng=' + lng_exact + '&' + param_sup;
        } else {
            requeteRecherche = path_relative_root + 'ajax_generate_xml_map.php?lat=' + latlng.geometry.location.lat() + '&lng=' + latlng.geometry.location.lng() + '&' + param_sup;
        }
    } else {
        requeteRecherche = path_relative_root + 'ajax_generate_xml_map.php?' + param_sup;
    }

    no_result = false;

    // to load only once
    horaire_txt = Translator.translate('horaires_map');
    close_txt = Translator.translate('close2');
    // we execute the search depending on the filter
    if (typeof not_init == undefined || !not_init) {
        initSearch();
    }

    downloadUrl(requeteRecherche, '100km', type_call, function (doc) {

        var xml = doc.responseXML;
        var marqueurs = xml.documentElement.getElementsByTagName("marqueur");
        clearOverlays();
        var listeLaterale = document.getElementById('list-store');

        if (document.getElementById('search_cp').value !== '') {
            listeLaterale.innerHTML = '';
        }
        if (marqueurs.length === 0) {
            $(".search-result").hide();
            displayLoader('show');
            listeLaterale.innerHTML = '<span class="no_result">' + Translator.translate('txt_no_near_shop') + '</span>';

            if (maCarte) {

                var latlng = new google.maps.LatLng(46.864716, 2.22);
                maCarte.setCenter(latlng);
                maCarte.setZoom(5);
            }

            // If we make a search we display th list
            if (type_call == 'button') {
                // If no result we display the map on initial position
                displayLoader('show');
                $('#store-nbr').html('');
                document.getElementById('list-store').innerHTML = '<span class="no_result">' + Translator.translate('txt_no_near_shop') + '</span>';
                $('.section-list-store').show();
            }
            return;
        } else if (type_call === 'button') {
            $('.section-list-store').show();

            if ($('.wrapper_retailers_map').length && !$('.product_page.mobile').length) {
                window.scrollTo(0, ($('.wrapper_retailers_map').offset().top - $("#site_head_wrap").height()))
            }
        }

        var query = '';

        if ($("#search_cp").val()) {
            query = '?search_cp=' + encodeURI($("#search_cp").val());
        }

        if (typeof lat_exact !== 'undefined' && typeof lng_exact !== 'undefined') {
            if (query != '') {
                query += '&';
            } else {
                query = '?';
            }
            query += 'lat=' + lat_exact + '&lng=' + lng_exact;
        }
        $(".search-result").show();

        // We create markers depending on the address
        var rectangleMarqueurs = new google.maps.LatLngBounds();
        // We check if the store is still open depending on user time
        var date = new Date();
        var time = (date.getHours() < 10 ? '0' : '') + date.getHours();
        time += (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();

        for (var i = 0; i < marqueurs.length; i++) {

            var nom = marqueurs[i].getAttribute('nom');
            var adresse = marqueurs[i].getAttribute('adresse');
            var ville = marqueurs[i].getAttribute('ville');
            var cp = marqueurs[i].getAttribute('cp');
            var pays = marqueurs[i].getAttribute('pays');
            var magasinId = marqueurs[i].getAttribute('magasin_id');
            var stock = marqueurs[i].getAttribute('stock');
            var stock_quantity = marqueurs[i].getAttribute('stock_quantity');
            var stockStatus = marqueurs[i].getAttribute('stock-status');
            var clientId = marqueurs[i].getAttribute('client_id');
            var favorite = marqueurs[i].getAttribute('favorite');
            var beContacted = marqueurs[i].getAttribute('contact_by_store');
            var picture_filtre = new Array();

            if (marqueurs[i].hasChildNodes()) {
                var filters = marqueurs[i].childNodes;
                for (var j = 0; j < filters.length; j++) {
                    picture_filtre.push(filters[j].getAttribute('picture_filtre'));
                }
            }

            if (cp.length === 4 && pays === 'France') {
                cp = '0' + cp;
            }

            var id = marqueurs[i].getAttribute('magasin_id');
            var image = marqueurs[i].getAttribute('image');
            var horaire = marqueurs[i].getAttribute('horaire');
            var horaire_open_pause = (marqueurs[i].getAttribute('horaire_open_pause') != null ? marqueurs[i].getAttribute('horaire_open_pause') : '');
            var horaire_close_pause = (marqueurs[i].getAttribute('horaire_close_pause') != null ? marqueurs[i].getAttribute('horaire_close_pause') : '');
            var horaire_open = 0;
            var horaire_close = 0;
            var horaire_close_pause_string = '';
            var horaire_open_pause_string = '';

            if (marqueurs[i].getAttribute('horaire_open') != null && marqueurs[i].getAttribute('horaire_open') != '') {
                horaire_open = marqueurs[i].getAttribute('horaire_open').replace(/:/g, '');
                horaire_close = marqueurs[i].getAttribute('horaire_close').replace(/:/g, '');
                var horaire_open_string = marqueurs[i].getAttribute('horaire_open').replace(":", "h");
                var horaire_close_string = marqueurs[i].getAttribute('horaire_close').replace(":", "h");

                if (horaire_close_pause !== "" && horaire_open_pause !== "") {
                    horaire_close_pause = marqueurs[i].getAttribute("horaire_close_pause").replace(/:/g, '');
                    horaire_open_pause = marqueurs[i].getAttribute("horaire_open_pause").replace(/:/g, '');
                    horaire_close_pause_string = marqueurs[i].getAttribute('horaire_close_pause').replace(":", "h");
                    horaire_open_pause_string = marqueurs[i].getAttribute('horaire_open_pause').replace(":", "h");
                }
            }

            var currently_open = 0;

            if (parseInt(horaire_open) < parseInt(time) && parseInt(time) < parseInt(horaire_close)) {
                currently_open = 1;
            }

            var horaire_today = marqueurs[i].getAttribute('horaire_today');

            // if the store is closed
            if (!horaire) {
                horaire_today = "";
            } else if (horaire && !currently_open) {
                horaire_today = close_txt;
            } else {
                if (horaire_open_string && horaire_close_string) {
                    if (horaire_close_pause !== '' && horaire_open_pause !== '') {
                        horaire_today = store_open_until_3a + horaire_open_string + store_open_until_3b + horaire_close_pause_string + store_open_until_3d +
                            horaire_open_pause_string + store_open_until_3b + horaire_close_string + store_open_until_3c;
                    } else {
                        horaire_today = store_open_until_2.replace(/\%s/, horaire_close_string);
                    }
                }
            }

            var horaire_plus = marqueurs[i].getAttribute('horaire_plus');

            if (horaire_plus) {
                horaire += '<span class="horaire_plus" style="display: none;">' + horaire_plus + '</span>';
            }

            if (marqueurs[i].getAttribute('holiday') !== '')
                horaire += '<br>' + marqueurs[i].getAttribute('holiday');
            var url = marqueurs[i].getAttribute("url");
            var telephone = marqueurs[i].getAttribute('tel');
            var email = marqueurs[i].getAttribute('email');
            var site = marqueurs[i].getAttribute('site');
            var magasin_type = marqueurs[i].getAttribute('magasin_type');
            var flag_available = marqueurs[i].getAttribute('flag_available');
            var lat = parseFloat(marqueurs[i].getAttribute("lat"));
            var lng = parseFloat(marqueurs[i].getAttribute("lng"));

            var distance = 0;

            if (marqueurs[i].getAttribute('distance')) {
                var distance = parseFloat(marqueurs[i].getAttribute('distance'));
            }

            // Filter shop
            var shop_filter = "";

            if (marqueurs[i].getAttribute("filtres").length) {
                shop_filter = marqueurs[i].getAttribute("filtres");
            }

            var horaire_formatted = marqueurs[i].getAttribute("horaire_formatted");

            if (horaire_formatted != "") {
                horaire_today = horaire_formatted;
            }

            var point = new google.maps.LatLng(parseFloat(marqueurs[i].getAttribute('lat')), parseFloat(marqueurs[i].getAttribute('lng')));
            var marqueur = creerMarqueur(point, nom, adresse, ville, cp, pays, horaire, telephone, email, site, image, magasin_type, (i + 1), id, url, distance, currently_open, horaire_today);
            var nouvelleLigneListe = creerLigneListe((i + 1), id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, picture_filtre, magasinId, stock, stockStatus, stock_quantity, clientId, favorite, beContacted, flag_available, url, shop_filter, telephone, query, lat, lng, horaire_plus);
            listeLaterale.appendChild(nouvelleLigneListe);

            markers.push(marqueur);
            rectangleMarqueurs.extend(point);

            if ($('#pdvBox').is(":visible")) {

                $('#pdvBox').addClass('deployed');
                setTimeout(function () {
                    $(".store-container").overlayScrollbars({});
                }, 250);
            }
        }

        if (document.querySelector("body.mobile .elem_list_contents")) {
            showStoreAll()
        }

        $('#store-nbr').html(marqueurs.length + ' ' + Translator.translate('recherche_magasins'));

        if (maCarte) {

            if ($("#retailers_map").length) {

                var myOptions = {
                    styles: google_map_style
                };

                var mcOptions = {
                    gridSize: 80,
                    imagePath: path_relative_root + 'img/maps/icns/cluster_0'
                };

                if (typeof MapsProviderService != "undefined") {
                    var mapHtml = document.getElementById('retailers_map');
                    maCarte = MapsProviderService.initSearchShopPage(mapHtml);
                } else {
                    maCarte = new google.maps.Map(document.getElementById("retailers_map"), myOptions);
                }

                clusterer = new MarkerClusterer(maCarte, markers, mcOptions);

                boundChanched(maCarte, markersArray);
                markers = [];
            }

            //We center the map on France
            if (document.getElementById('search_pays').value === 'France' && document.getElementById('search_cp').value === '') {
                var latlng = new google.maps.LatLng(46.864716, 2.2);
                maCarte.setZoom(5);
                maCarte.setCenter(latlng);
            } else {
                // We center depending on the markers found
                maCarte.setCenter(rectangleMarqueurs.getCenter(),
                    maCarte.fitBounds(rectangleMarqueurs));
                // adjust zoom lvl on geoloc
                $('#btn_geoloc').on('click tap touch', function () {
                    setTimeout(() => {
                        maCarte.setZoom(10);
                    }, 1000);
                });

                //adjust zoom lvl on search with result
                $(document).ready(function () {
                    if ($('.loc_input.pac-target-input').val().trim() !== '') {
                        setTimeout(() => {
                            maCarte.setZoom(10);
                        }, 1000);
                    }
                });
            }

            if (marqueurs.length === 1)
                maCarte.setZoom(16);
        }
        displayLoader('show');
    });
}

function sendMailAlertStock(type_alert, form, itm_rr_id) {
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '_' + id + itm_rr_id;

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();

    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {
        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {
        $('#mail_alerte_stock' + elem).removeClass('inputErr');

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function (response) {
                $('#bloc_add_alert' + elem + ' .alert_stock').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
                $('#bloc_add_alert' + elem + ' .title_disponibilite').hide();
            },
            fail: function () {
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    var has_errors = false;

    $(formId + " .return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $('#return_products_form_' + idOrder).serialize();

        data += '&idOrder=' + (multiTransp ? idOrderTransp : idOrder) + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}

// Commande mobile end


function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

function alertMobile(msg)
{
    var $alertbox = $( '#alertbox' );
    var $shad = $('#shade');
    var html = '<div class="modbox_title">' + msg + '</div>';
    html += '<div class="modbox_buttons"><button class="button cancel" onclick="modBoxClose.call(document.getElementById(\'alertbox\'));" class="btn_alert"><span>' + Translator.translate('close') + '</span></button></div>';
    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox" class="modbox" style="height:auto;">' + html + '</div>');
    }
    $shad.addClass( 'visible' );
    $alertbox.appendTo( 'body' ).addClass('open').css('display', 'block');
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });

    initDailymotionVideos();

    setTimeout(function () {
        if ($('#cart_total .tilli-container').length) {
            $('#cart_total .tilli-container').appendTo('.share_cart');
            $('.share_cart').addClass('tilliWrapper');
        }
    }, 1000);
});
function setAvailableSizes() {
    let produit_id = $('#produit_principal').val();
    let data = {produit_id: new Array(produit_id)};

    if (isGlobalEActive) {
        data.warehouse_only = 1;
    }

    let elem_id =  $('#prod_couleur').val();

    $.ajax({
        url: path_relative_root + 'ajax_get_colisages_states_by_color_size.php',
        type: 'post',
        data: data,
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }
            if (res.success && res.result && res.result[produit_id] != undefined && res.result[produit_id][elem_id] != undefined) {
                var sizes_tab = res.result[produit_id][elem_id];
                var size_box, is_disabled;
                var has_stock = res.result[produit_id]['stock_quantite'] > 0;

                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        // Sélectionner la div correspondante avec les bons attributs
                        size_box = $('.form_itm.check.size').filter(function() {
                            return $(this).data('sizeid') == size_id && $(this).data('produitid') == produit_id;
                        });
                        // Vérifier si la taille est disponible ou non (d'après le stock)
                        is_disabled = !sizes_tab[size_id];
                
                        // Manipulation des classes et de l'attribut disabled
                        if (!is_disabled) {
                            size_box.removeClass('disabled'); // Retirer la classe 'disabled' de la div
                            size_box.find('label').removeClass('disabled'); // Retirer la classe 'disabled' du label, si applicable
                        } else {
                            size_box.addClass('disabled'); // Ajouter la classe 'disabled' pour désactiver visuellement la div
                            size_box.find('label').addClass('disabled'); // Ajouter la classe 'disabled' au label
                        }
                    }
                }                
            }
        }
    });
}
